import { Injectable } from '@angular/core';
import {
  CommonStandardServiceStackForm,
  CommonStandardServiceStackFormComponent,
  CommonUserServiceStackFormComponent,
  CommonUserServiceStackForm,
  COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME,
  COMMON_STANDARD_SERVICE_STACK_FORM_FEATURE_NAME,
  OBJECT_STORAGE_SERVICE_STACK_FORM_FEATURE_NAME,
  ObjectStorageServiceStackFormComponent,
  ObjectStorageServiceStackForm,
  SharedStorageServiceStackForm,
  SharedStorageServiceStackFormComponent,
  SHARED_STORAGE_SERVICE_STACK_FORM_FEATURE_NAME
} from '@zerops/zerops/feature/service-stack-add-form/modules';
import { ServiceStackTypes } from '@zerops/models/service-stack';

export const RESOLVER_MAP = {
  [ServiceStackTypes.Elasticsearch]: {
    elasticsearch_v6: {
      formInstance: CommonStandardServiceStackForm,
      formComponent: CommonStandardServiceStackFormComponent,
      translationKey: COMMON_STANDARD_SERVICE_STACK_FORM_FEATURE_NAME
    },
    elasticsearch_v7: {
      formInstance: CommonStandardServiceStackForm,
      formComponent: CommonStandardServiceStackFormComponent,
      translationKey: COMMON_STANDARD_SERVICE_STACK_FORM_FEATURE_NAME
    },
  },
  [ServiceStackTypes.Redis]: {
    keydb_v6: {
      formInstance: CommonStandardServiceStackForm,
      formComponent: CommonStandardServiceStackFormComponent,
      translationKey: COMMON_STANDARD_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.RabbitMq]: {
    rabbitmq_v3: {
      formInstance: CommonStandardServiceStackForm,
      formComponent: CommonStandardServiceStackFormComponent,
      translationKey: COMMON_STANDARD_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.MariaDb]: {
    mariadb_v10_3: {
      formInstance: CommonStandardServiceStackForm,
      formComponent: CommonStandardServiceStackFormComponent,
      translationKey: COMMON_STANDARD_SERVICE_STACK_FORM_FEATURE_NAME
    },
    mariadb_v10_4: {
      formInstance: CommonStandardServiceStackForm,
      formComponent: CommonStandardServiceStackFormComponent,
      translationKey: COMMON_STANDARD_SERVICE_STACK_FORM_FEATURE_NAME
    },
    mariadb_v10_6: {
      formInstance: CommonStandardServiceStackForm,
      formComponent: CommonStandardServiceStackFormComponent,
      translationKey: COMMON_STANDARD_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.MongoDb]: {
    mongodb_v4: {
      formInstance: CommonStandardServiceStackForm,
      formComponent: CommonStandardServiceStackFormComponent,
      translationKey: COMMON_STANDARD_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.Postgres]: {
    postgresql_v12: {
      formInstance: CommonStandardServiceStackForm,
      formComponent: CommonStandardServiceStackFormComponent,
      translationKey: COMMON_STANDARD_SERVICE_STACK_FORM_FEATURE_NAME
    },
    postgresql_v14: {
      formInstance: CommonStandardServiceStackForm,
      formComponent: CommonStandardServiceStackFormComponent,
      translationKey: COMMON_STANDARD_SERVICE_STACK_FORM_FEATURE_NAME
    },
    postgresql_v16: {
      formInstance: CommonStandardServiceStackForm,
      formComponent: CommonStandardServiceStackFormComponent,
      translationKey: COMMON_STANDARD_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.Valkey]: {
    valkey_v7_2: {
      formInstance: CommonStandardServiceStackForm,
      formComponent: CommonStandardServiceStackFormComponent,
      translationKey: COMMON_STANDARD_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.Qdrant]: {
    qdrant_v1_10: {
      formInstance: CommonStandardServiceStackForm,
      formComponent: CommonStandardServiceStackFormComponent,
      translationKey: COMMON_STANDARD_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.Nats]: {
    nats_v2_10: {
      formInstance: CommonStandardServiceStackForm,
      formComponent: CommonStandardServiceStackFormComponent,
      translationKey: COMMON_STANDARD_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.PhpNginx]: {
    php_nginx_v7_3: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    php_v7_4_nginx_v1_20: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    php_v7_4_nginx_v1_22: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    php_v8_0_nginx_v1_20: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    php_v8_0_nginx_v1_22: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    php_v8_1_nginx_v1_20: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    php_v8_1_nginx_v1_22: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    php_v8_2_nginx_v1_22: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    php_v8_3_nginx_v1_22: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.PhpApache]: {
    php_apache_v7_3: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    php_v7_4_apache_v2_4: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    php_v8_0_apache_v2_4: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    php_v8_1_apache_v2_4: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    php_v8_2_apache_v2_4: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    php_v8_3_apache_v2_4: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.Nginx]: {
    nginx_v1_16: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    nginx_v1_18: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    nginx_v1_20: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    nginx_v1_22: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    nginx_v1_24: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.NodeJs]: {
    nodejs_v10: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    nodejs_v12: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    nodejs_v13: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    nodejs_v14: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    nodejs_v16: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    nodejs_v18: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    nodejs_v20: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    nodejs_v22: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.Ubuntu]: {
    ubuntu_v22: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.Alpine]: {
    alpine_v3_17: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    alpine_v3_18: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    alpine_v3_19: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    alpine_v3_20: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.Golang]: {
    golang_v1: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.ObjectStorage]: {
    object_storage_v1: {
      formInstance: ObjectStorageServiceStackForm,
      formComponent: ObjectStorageServiceStackFormComponent,
      translationKey: OBJECT_STORAGE_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.SharedStorage]: {
    shared_storage_v1: {
      formInstance: SharedStorageServiceStackForm,
      formComponent: SharedStorageServiceStackFormComponent,
      translationKey: SHARED_STORAGE_SERVICE_STACK_FORM_FEATURE_NAME
    },
    moosefs_v3: {
      formInstance: SharedStorageServiceStackForm,
      formComponent: SharedStorageServiceStackFormComponent,
      translationKey: SHARED_STORAGE_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.DotNet]: {
    dotnet_v6: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    dotnet_v7: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    dotnet_v8: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.Rust]: {
    rust_v1: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    rust_v1_78: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    rust_v1_80: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    rust_nightly: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.Python]: {
    python_v3_8: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    python_v3_9: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    python_v3_10: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    python_v3_11: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    python_v3_12: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.Static]: {
    static: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.Bun]: {
    bun_v1_1: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.Deno]: {
    deno_v1: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    }
  },
  [ServiceStackTypes.Java]: {
    java_v17: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    },
    java_v21: {
      formInstance: CommonUserServiceStackForm,
      formComponent: CommonUserServiceStackFormComponent,
      translationKey: COMMON_USER_SERVICE_STACK_FORM_FEATURE_NAME
    }
  }
};

@Injectable({ providedIn: 'root' })
export class ServiceStackAddFormResolverService {
  private _serviceStackResolverMap = RESOLVER_MAP;

  resolve<F = any, C = any>(
    type: string,
    version: string
  ): { formInstance: F; formComponent: C; translationKey: string } {
    if (!this._serviceStackResolverMap[type] || !this._serviceStackResolverMap[type][version]) {
      throw new Error(`Service Stack Add resolver for type ${type} and ${version} doesn't exist.`);
    }

    return this._serviceStackResolverMap[type][version];
  }

}
